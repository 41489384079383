import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { SuperAdminContext } from "../../contexts/superadminContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./SuperAdminNavBar.css";
import { getCurrentSSOUser } from "../../services/authService";

const SuperAdminNavBar = ({ user }) => {
  const [superAdmin, changeDetails] = useContext(SuperAdminContext);
  const history = useNavigate();
  const [email, setemail] = useState("");
  const [link, setlink] = useState("");
  const [userName, setName] = useState("");

  useEffect(() => {
    getCurrentSSOUser().then((r) => {
      if (r && r.name) {
        setName(r.name);
      }
    });
  }, []); // Add empty dependency array to run only once

  const generateLink = () => {
    if (email != "") {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}invetations/`, {
          email,
        })
        .then((res) => {
          setlink(res.data);
        });
    }
  };

  function copyToClipBoard() {
    /* Get the text field */
    var copyText = document.getElementById("myInput");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  }

  return (
    <div class="main">
      <ToastContainer />
      <aside>
        <div class="sidebar left ">
          <div class="user-panel">
            <div class="pull-left info">
              <p>{userName}</p>
            </div>
          </div>
          <ul class="list-sidebar bg-defoult">
            <li>
              {" "}
              <a href="/superadmin/home">
                <i class="fa fa-th-large"></i>
                <span class="nav-label">Home</span>
              </a>{" "}
            </li>
            <li>
              {" "}
              <a
                href="#"
                data-toggle="collapse"
                data-target="#dashboard"
                class="collapsed active"
              >
                {" "}
                <i class="fa fa-th-large"></i>{" "}
                <span class="nav-label"> Utilities </span>{" "}
                <span class="fa fa-chevron-right pull-right"></span>{" "}
              </a>
              <ul class="sub-menu collapse" id="dashboard">
                <li class="active">
                  <a
                    onClick={() => {
                      changeDetails({
                        region: "all",
                      });
                      localStorage.setItem("region", "all");
                      history("/superadmin/managesuperadmin");
                    }}
                  >
                    Super Admins
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      changeDetails({
                        region: "all",
                      });
                      localStorage.setItem("region", "all");
                      history("/superadmin/coachroom");
                    }}
                  >
                    Coaching Rooms
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      changeDetails({
                        region: "all",
                      });
                      localStorage.setItem("region", "all");
                      history("/superadmin/users");
                    }}
                  >
                    User Management
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      changeDetails({
                        region: "all",
                      });
                      localStorage.setItem("region", "all");
                      history("/superadmin/sessions", {
                        state: { region: undefined },
                      });
                    }}
                  >
                    Sessions
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      changeDetails({
                        region: "all",
                      });
                      localStorage.setItem("region", "all");
                      history("/superadmin/agenda", {
                        state: { region: undefined },
                      });
                    }}
                  >
                    Agenda
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      changeDetails({
                        region: "all",
                      });
                      localStorage.setItem("region", "all");
                      history("/superadmin/templates", {
                        state: { region: undefined },
                      });
                    }}
                  >
                    Email Templates
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      changeDetails({
                        region: "all",
                      });
                      localStorage.setItem("region", "all");
                      history("/superadmin/innerproject", {
                        state: { region: undefined },
                      });
                    }}
                  >
                    Projects
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      changeDetails({
                        region: "all",
                      });
                      localStorage.setItem("region", "all");
                      history("/superadmin/failedParticiapnats", {
                        state: { region: undefined },
                      });
                    }}
                  >
                    Failed Submissions
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      history({
                        pathname: "/superadmin/revokerequests",
                      });
                    }}
                  >
                    Revoke Requests
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      history({
                        pathname: "/superadmin/mailgroups",
                      });
                    }}
                  >
                    MailGroups Sent
                  </a>
                </li>
              </ul>
            </li>
            <li>
              {" "}
              <a
                href="#"
                data-toggle="collapse"
                data-target="#dashboard2"
                class="collapsed active"
              >
                {" "}
                <i class="fa fa-diamond"></i>{" "}
                <span class="nav-label">Events</span>
                <span class="fa fa-chevron-right pull-right"></span>{" "}
              </a>
              <ul class="sub-menu collapse" id="dashboard2">
                <li>
                  {" "}
                  <a
                    onClick={() => {
                      history({
                        pathname: "/entrance",
                      });
                    }}
                  >
                    {" "}
                    <span class="nav-label">Join Event</span>{" "}
                  </a>
                </li>
              </ul>
            </li>

            <li>
              {" "}
              <a
                data-toggle="collapse"
                data-target="#products"
                class="collapsed active"
              >
                {" "}
                <span class="fa fa-chevron-right pull-right"></span>{" "}
                <span class="nav-label">Actions</span>{" "}
              </a>
              <ul class="sub-menu collapse" id="products">
                <li class="active">
                  <a
                    onClick={() => {
                      axios
                        .get(`${process.env.REACT_APP_SERVER_URL}cleardata/`, {})
                        .then((r) => {
                          toast("DB Cleared Successfully", { autoClose: 5000 });
                        })
                        .catch((err) => {
                          console.log(err);
                          toast("Something went wrong!", { autoClose: 5000 });
                        });
                    }}
                  >
                    Clear DB
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      axios
                        .get(
                          `${process.env.REACT_APP_SERVER_URL}cleardata/seedcriterias`,
                          {}
                        )
                        .then((r) => {
                          toast("DB Seeded Successfully", { autoClose: 5000 });
                        })
                        .catch((err) => {
                          console.log(err);
                          toast("Something went wrong!", { autoClose: 5000 });
                        });
                    }}
                  >
                    Seed DB
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      axios
                        .get(
                          `${process.env.REACT_APP_SERVER_URL}cleardata/changemailstodummy`,
                          {}
                        )
                        .then((r) => {
                          toast("mails changed Successfully", {
                            autoClose: 5000,
                          });
                        })
                        .catch((err) => {
                          console.log(err);
                          toast("Something went wrong!", { autoClose: 5000 });
                        });
                    }}
                  >
                    Change mails to dummy
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default SuperAdminNavBar;
